<div class="" *ngIf="selectAddressTaglineCheck">
    <div class="pe-4">
        <div class="fwt-cart-card d-flex align-items-center justify-content-between mb-4">
            <div>
                <h2 class="section-title mb-1 d-sm-block">Select a Shipping Address</h2>
            </div>
            <div>
                <p class="step-1 d-sm-none d-block">Step 2/3</p>
            </div>
        </div>
    </div>
</div>
<div class="mobile-view">
    <div class="d-flex mb-2">
        <div class="left-arrow" (click)="toDefalultPage()"> <img src="/assets/media/svg/back.svg" alt="left-arrow">
        </div>
        <div class="my-addresses ">My Addresses</div>
    </div>
</div>



<div class="">
    <div class="row align-items-start-sm" *ngIf="shippingAdressList">
        <div class=" col col-lg-6 " *ngFor="let itemAdress of shippingAdressList;let i = index ">
            <div class="addressAdd listItemAddress">
                <div class="d-flex">
                    <div *ngIf="shippingAdressList"><input type="radio" class="p-2" id="f-option-{{i}}" name="selector"
                            [(ngModel)]="selectedAddress" [value]="itemAdress" [checked]="i === 0"></div>
                    <div class="listItemName">{{itemAdress.fullName}}</div>
                </div>
                <div class="listItemEmail">Email: {{itemAdress.email}}</div>
                <div class="listItemEmail">{{itemAdress.address}}</div>
                <span class="listItemEmail">{{itemAdress.city}} {{itemAdress.state}} {{itemAdress.pincode}}</span>
                <!-- <span class="listItemEmail">{{itemAdress.state}}</span>
                <span class="listItemEmail"></span> -->
                <div>
                    <a class="listItemEdit" (click)="changeAddress(itemAdress)">EDIT</a>
                    <a class="text-danger address-delete-btn" (click)="deleteAddress(itemAdress.id)">DELETE</a>
                </div>
            </div>
        </div>

        <div class=" col col-lg-6 ">
            <div class="newAddress ">
                <div class="addressAdd" (click)="addNewAddress()">+ Add New Address</div>
            </div>
        </div>

    </div>
</div>
<!-- <div class="shipping-address pb-0">

        </div> -->