import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { SessionsStorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import {
  CartItemList,
  CartItemRequest,
  CartRequestModel,
} from '../../modules/shop/model/cart.model';
import { AppHttpClient } from './app-http.service';
/**
 * Service to show tostr message to the user
 */
@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private sessionStorageService: SessionsStorageService,
    private http: AppHttpClient
  ) {}
  private baseUrl = `${environment.apiUrl}/app/v1/cart`;

  key = 'productUUID';
  myuuid = uuidv4();

  setCartUID() {
    const value = this.myuuid;
    this.sessionStorageService.set(this.key, value);
    return value;
  }
  getCartUID() {
    const uuidValue = this.sessionStorageService.get(this.key);

    if (uuidValue) {
      return uuidValue;
    } else return '';
  }
  deleteCartUID() {
    this.sessionStorageService.remove(this.key);
  }
  updateCartInfo(cartUpdate: CartRequestModel) {
    return this.http.post<CartRequestModel>(
      `${this.baseUrl}/update`,
      cartUpdate
    );
  }

  getCart(cartItem: CartItemRequest) {
    return this.http.post<CartItemList>(`${this.baseUrl}`, cartItem);
  }
}
