import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AppHttpClient } from '../../../../core/services/app-http.service';
import {
  GetShippingAddressModel,
  ShippingAddressRequestModel,
} from '../../address/model/shipping-address.model';
import {
  MyOrdersRequestModel,
  MyOrdersResponseModel,
  PlaceMyOrderRequestModel,
  PlaceMyOrderResponseModel,
  RetryMyOrderRequestModel,
  RetryMyOrderResponseModel,
} from '../../address/model/my-orders.model';
import { MyOrderDetailsModel } from '../model/my-order-details.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyOrdersService {
  private baseUrl = `${environment.apiUrl}/app/v1/order`;

  constructor(private http: AppHttpClient) {}

  getOrderBuUserId(myOrderId: number): Observable<any> {
    return this.http.get<MyOrderDetailsModel>(`${this.baseUrl}/${myOrderId}`);
  }
  getMyOrders(myOrdersRequestModel: MyOrdersRequestModel) {
    return this.http.post<MyOrdersResponseModel>(
      `${this.baseUrl}/list`,
      myOrdersRequestModel
    );
  }
  rePlaceMyOrder(retryMyOrderRequestModel: RetryMyOrderRequestModel) {
    return this.http.post<RetryMyOrderResponseModel>(
      `${this.baseUrl}/payment/retry`,
      retryMyOrderRequestModel
    );
  }
  placeMyOrder(placeMyOrderRequestModel: PlaceMyOrderRequestModel) {
    return this.http.post<PlaceMyOrderResponseModel>(
      `${this.baseUrl}/place`,
      placeMyOrderRequestModel
    );
  }
}
