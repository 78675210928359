<div class="container">
    <div class="couponAndOffers"> Coupons and offers</div>
    <form [formGroup]="couponForm" (ngSubmit)="applyCoupon()">
        <div class="">
            <input type="text" id="" name="offerCode" formControlName="offerCode"
                class="form-control form-fields text-dark" placeholder="Enter coupon code" autocomplete="off" /> <button
                type="submit" class="apply-coupon">Apply</button>
            <!-- <label for="fullName">Enter coupon code</label> -->
        </div>
        <div *ngIf="couponError" class="isApplicable mt-3">
            <div class="couponError">This coupon is not applicable.</div>
        </div>
        <div class="available-coupons">Available coupons</div>
        <div *ngIf="offerList">
            <div *ngFor="let offerItem of offerList">
                <div class="d-flex justify-content-between coupon-parent">
                    <div class="coupon-code"><span class="coupon-name">{{offerItem.offerCode}}</span></div>
                    <div><button type="button" class="appy-co"
                            (click)="applyAvailableOffer(offerItem.offerCode)">Apply</button></div>
                </div>
                <div class="saved-amount pt-3">You will save Rs. {{offerItem.discount}} with this coupon</div>
                <div class="off-amount pt-2">Get {{offerItem.maxDiscount}}% off on items total above Rs.
                    {{offerItem.minCartValue}}.</div>
            </div>
        </div>
    </form>
</div>