<!-- <div class="cart-coupons-details mb-3" *ngIf="offerCode">
    <div class="d-flex ">
        <div>
          
        </div>
        <div>
            <div class="d-flex justify-content-between">
               
                
            </div>
        </div>
    </div>



</div> -->
<!-- <div class="col-lg-12  mt-lg-0 mt-4"> -->
<div class="d-flex cart-coupons-details mb-3 justify-content-between" *ngIf="offerCode && cartDetails">
    <div class=" d-flex justify-content-between">
        <div><img src="assets/media/svg/coupons.svg" alt=""></div>
        <div class="px-2">
            <div class="coupon-applied">"{{offerCode}}" applied</div>
            <div class="saved-additional">You Saved addionaly Rs. {{cartDetails.discount}} </div>
        </div>

    </div>

    <!-- <div class="">
           
        </div> -->

    <div class="">
        <div class=""></div>
    </div>
</div>
<!-- </div> -->
<div class="cart-coupons-details" *ngIf="!(offerCode)">
    <div class="d-flex align-items-start">
        <span>%</span>
        <div (click)="applyOffers()">
            <h6>Coupons and offers</h6>
            <p class="mt-2">Save more with coupons and offers</p>
        </div>
    </div>
</div>
<div class="cart-coupons-details mt-3" *ngIf="cartDetails">
    <div class="fwt-dooted-border">
        <ul class="d-flex align-items-center justify-content-between mb-3">
            <li>
                <p>Item total</p>
            </li>
            <li>
                <p>Rs. {{cartDetails.totalAmount}}</p>
            </li>
        </ul>
        <ul class="d-flex align-items-center justify-content-between mb-0">
            <li>
                <p>Delivery fee</p>
            </li>
            <li>
                <h5>{{cartDetails.deliveryCharges}}</h5>
            </li>
        </ul>
    </div>
    <div class="fwt-dooted-border">
        <ul class="d-flex align-items-center justify-content-between mb-3">
            <li>
                <p><b>Grand Total</b></p>
            </li>
            <li>
                <p><b>Rs. {{cartDetails.totalAmount}}</b></p>
            </li>
        </ul>
        <ul class="d-flex align-items-center justify-content-between mb-0">
            <li>
                <h6>Inclusive of all taxes</h6>
            </li>
        </ul>
    </div>
    <div class="payment-checkout-btn mb-2">
        <div class="saved-tagline mt-3">
            <div *ngIf="offerCode" class="saved-so-far"> Rs. {{cartDetails.discount}} saved so far on this order</div>
        </div>

        <button class="primary-btn w-100 mt-4" type="button" (click)="checkOut()"> CHECKOUT
            <!-- <ng-container *ngIf="!isLoading; else loading;">
                    CHECKOUT
                </ng-container>
                <ng-template #loading>
                    <div class="spinner-border spinner-border-sm spinner-circle-size" role="status">
                    </div>
                </ng-template> -->
        </button>
    </div>
</div>


<!-- </div> -->