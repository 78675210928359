import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import {
  CouponRequestModel,
  CouponResponseModel,
  GetOfferListModel,
  OfferlistResponseModel,
} from '../model/coupon-and-offer.model ';
import { environment } from '../../../../environments/environment';
import { AppHttpClient } from '../../../core/services/app-http.service';

@Injectable({
  providedIn: 'root',
})
export class CouponAndOfferService {
  private baseUrl = `${environment.apiUrl}/app/v1/offer`;

  constructor(private http: AppHttpClient) {}

  private myCouponCode = new BehaviorSubject<string>('');

  setValue(value: string) {
    this.myCouponCode.next(value);
  }

  getValue() {
    return this.myCouponCode.asObservable();
  }

  getCouponAndOfferList(offerListModel: GetOfferListModel) {
    return this.http.post<OfferlistResponseModel[]>(
      `${this.baseUrl}/list`,
      offerListModel
    );
  }
  validateOffer(couponModel: CouponRequestModel) {
    return this.http.post<CouponResponseModel>(
      `${this.baseUrl}/validate`,
      couponModel
    );
  }
}
