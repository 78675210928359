import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartItemList } from '../model/cart.model';
import { CommonModule } from '@angular/common';
import { SessionsStorageService } from '../../../core/services/storage.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CouponAndOfferComponent } from '../coupon-and-offer/coupon-and-offer.component';
import { CouponAndOfferService } from '../services/coupon-and-offer.service';
import { CouponResponseModel } from '../model/coupon-and-offer.model ';

@Component({
  selector: 'app-total-amount-checkout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './total-amount-checkout.component.html',
  styleUrl: './total-amount-checkout.component.scss',
})
export class TotalAmountCheckoutComponent implements OnInit {
  offerCode!: string;
  couponError: boolean = false;
  offerCodeResponse!: CouponResponseModel;
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private couponAndOfferService: CouponAndOfferService
  ) {}
  ngOnInit(): void {
    this.getCouponCode();
  }
  @Input() cartDetails!: CartItemList;
  @Input() activeTab: string = 'cart1';
  @Output() activeTabEvent = new EventEmitter<string>();
  @Output() activeTabNEwEvent = new EventEmitter<string>();

  checkOut() {
    const tokenCheck = this.auth.getToken();
    if (tokenCheck) {
      if (this.activeTab == 'cart1') {
        this.activeTab = 'cart2';
        this.activeTabEvent.emit(this.activeTab);
      } else {
        this.activeTab = 'cart3';
        this.activeTabEvent.emit(this.activeTab);
      }
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          returnUrl: '/cart?step=2',
        },
      });
    }
  }

  getCouponCode() {
    this.couponAndOfferService.getValue().subscribe((resp) => {
      this.offerCode = resp;
    });
  }

  // Coupon and Offer Dialogue open
  applyOffers() {
    const modalRef = this.modalService.open(CouponAndOfferComponent, {
      centered: true,
      size: 'confirm-size',
    });

    modalRef.result.then(
      (isApplicable) => {
        if (isApplicable == true) {
          this.getCouponCode();
        } else {
          this.couponError = true;
        }
      }
      // () => this.getCouponCode(),
    );
  }
}
