import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ReviewsModel,
  ReviewsModelDetails,
} from '../reviews/model/reviews.model';

@Component({
  selector: 'app-paginantor',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './paginantor.component.html',
  styleUrl: './paginantor.component.scss',
})
export class PaginantorComponent implements OnInit {
  @Input() activePaginationClass: boolean = false;
  @Input() totalPages: number = 1;
  @Output() pageChange = new EventEmitter<number>();

  pages: number[] = [];
  currentPage: number = 1;

  ngOnInit() {
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pageChange.emit(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.pageChange.emit(this.currentPage);
    }
  }
}
