import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AppHttpClient } from '../../../../core/services/app-http.service';
import {
  GetShippingAddressModel,
  ShippingAddressRequestModel,
} from '../model/shipping-address.model';
import { StateModel } from '../model/state.model';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private baseUrl = `${environment.apiUrl}/app/v1/state`;

  constructor(private http: AppHttpClient) {}

  getState() {
    return this.http.get<StateModel[]>(`${this.baseUrl}/list`);
  }
}
