import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AppHttpClient } from '../../../../core/services/app-http.service';
import { GetShippingAddressModel, ShippingAddressRequestModel } from '../model/shipping-address.model';

@Injectable({
  providedIn: 'root',
})
export class ShippingAddessService {
  private baseUrl = `${environment.apiUrl}/app/v1/address`;

  constructor(private http: AppHttpClient) {}

  addShippingAddress(shippingAddressRequestModel: ShippingAddressRequestModel) {
    return this.http.post<ShippingAddressRequestModel>(`${this.baseUrl}`, shippingAddressRequestModel);
}
updateAddress(shippingAddressRequestModel: ShippingAddressRequestModel){
  return this.http.put<ShippingAddressRequestModel>(`${this.baseUrl}/${shippingAddressRequestModel.id}`, shippingAddressRequestModel);
}
  getShippingAddess() {
    return this.http.get<GetShippingAddressModel[]>(`${this.baseUrl}`);
  }
  // getItemBySlug(slug:string,cartUniqeId:string) : Observable<ShippingAddessDetailsModel> {
  //   return this.http.get<ShippingAddessDetailsModel>(`${this.baseUrl}/slug/${slug}?cartUniqueId=${cartUniqeId}`);
  // }
  deleteAddess(id:number) {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
