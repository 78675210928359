import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartItemList } from '../model/cart.model';
import { ShippingAddessService } from '../address/services/shipping-address.service';
import { GetShippingAddressModel } from '../address/model/shipping-address.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TotalAmountCheckoutComponent } from '../total-amount-checkout/total-amount-checkout.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-address-list',
  standalone: true,
  templateUrl: './user-address-list.component.html',
  styleUrl: './user-address-list.component.scss',
  imports: [CommonModule, FormsModule, TotalAmountCheckoutComponent],
})
export class UserAddressListComponent {
  @Input() selectAddressTaglineCheck: boolean = true;
  @Input() shippingAdressList!: GetShippingAddressModel[];
  @Input() cartDetails!: CartItemList;
  @Output() selectedAddressEvent = new EventEmitter<number>();
  @Output() dataEvent = new EventEmitter<boolean>();
  @Output() changeAddressEvent = new EventEmitter<GetShippingAddressModel>();

  selectedAddress!: GetShippingAddressModel;

  constructor(
    private shippingAddressService: ShippingAddessService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  addNewAddress() {
    const data = false;
    this.dataEvent.emit(data);
  }

  deleteAddress(id: number) {
    this.shippingAddressService.deleteAddess(id).subscribe((resp) => {
      resp;
      this.getAddress();
    });
  }

  changeAddress(item: GetShippingAddressModel) {
    this.changeAddressEvent.emit(item);
  }
  ngOnChanges() {
    this.getSelectedAddress();
  }
  getSelectedAddress() {
    if (this.shippingAdressList && this.shippingAdressList.length > 0) {
      this.selectedAddress = this.shippingAdressList[0];
      this.sendSelectedAddressForPayement();
    }
  }
  getAddress() {
    this.shippingAddressService.getShippingAddess().subscribe((resp) => {
      this.shippingAdressList = resp;
    });
  }

  sendSelectedAddressForPayement() {
    this.selectedAddressEvent.emit(this.selectedAddress?.id);
  }
  // handleActiveTab(data:string){
  //   this.activeTab = data;
  //   this.activeTabEvent.emit(this.activeTab);
  // }

  toDefalultPage() {
    this.router.navigate(['/my-account/account']);
  }
}
