import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CouponAndOfferService } from '../services/coupon-and-offer.service';
import {
  CouponResponseModel,
  GetOfferListModel,
  OfferlistResponseModel,
} from '../model/coupon-and-offer.model ';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coupon-and-offer',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './coupon-and-offer.component.html',
  styleUrl: './coupon-and-offer.component.scss',
})
export class CouponAndOfferComponent implements OnInit {
  couponForm: FormGroup;
  message: string = '';
  getofferList: GetOfferListModel = { search: '' };
  offerList: OfferlistResponseModel[] = [];
  couponOfferResponse!: CouponResponseModel;
  couponError: boolean = false;
  constructor(
    private fb: FormBuilder,
    private couponOfferService: CouponAndOfferService,
    public modal: NgbActiveModal
  ) {
    this.couponForm = this.fb.group({
      offerCode: ['', Validators.required],
    });
  }
  ngOnInit(): void {
    this.getCouponList();
  }
  applyAvailableOffer(offerCode: string) {
    this.couponForm.patchValue({ offerCode });
  }
  getCouponList() {
    this.couponOfferService
      .getCouponAndOfferList(this.getofferList)
      .subscribe((resp) => {
        this.offerList = resp;
      });
  }

  applyCoupon() {
    if (this.couponForm.valid) {
      this.couponOfferService.validateOffer(this.couponForm.value).subscribe(
        (resp: CouponResponseModel) => {
          if (!resp) {
            this.couponError = true;
          }
          this.couponOfferResponse = resp;
          if (this.couponOfferResponse.isApplicable == true) {
            this.couponOfferService.setValue(this.couponForm.value.offerCode);
            this.modal.close(this.couponOfferResponse.isApplicable);
          } else {
            this.couponError = true;
          }
        },
        (error) => {
          // Handle error response
          if (error.status === 400) {
            this.message = error.error?.message || 'Offer details not found.';
          } else {
            this.message =
              'An error occurred while validating the coupon. Please try again.';
          }
          this.couponError = true;
        }
      );
    } else {
      this.message = 'Please enter a valid coupon code and select an offer.';
    }
  }
}
